import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { TextList, TextListItem } from '@naf/text-list';
import { Button } from '@naf/button';
import * as S from '../Styles';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb';
import { useServiceCalculator } from '../context/ServiceCalculatorContext';
import VehicleIcon from '../../../../components/icons/VehicleIcon';
import NafCenterOffer from '../../../../../assets/images/NafCenterOffer.svg';
import { funnelPayload } from '../../../../../../types/gtmFunnel';
import { useSendGTMEventOnce } from '../../../../hooks/GTM/useSendGTMEventOnce';
import { useGTMDataLayer } from '../../../../hooks/GTM/useGTMDataLayer';
import { handleButtonClick } from '../ServiceCalculator.utils';

const TitleComponent = () => <Text variant={TextVariant.Header1}>Ditt serviceforslag</Text>;

const PriceOfferContentComponent = () => {
  const dataLayer = useGTMDataLayer();
  const { offer, vehicleForm, updateShouldShowManualRequest, updateShouldShowAnOffer } = useServiceCalculator();
  const { licensePlateNumber, vehicleName, mileage } = vehicleForm.getValues();

  const selectedWorkItem = offer.vehicleMaintenanceWizard.mainWorkItems.find((x) => x.isSelected);

  return (
    <>
      <S.StyledVehicleText>
        <VehicleIcon />
        <Text variant={TextVariant.BodyTextHeader}>
          {licensePlateNumber} {vehicleName}, {mileage} km
        </Text>
      </S.StyledVehicleText>
      <S.StyledOfferCard>
        <Text variant={TextVariant.SubHeader} style={{ marginTop: 0 }}>
          {selectedWorkItem.text}
        </Text>
        <TextList variant="bullet">
          {selectedWorkItem.items.map((item) => (
            <TextListItem key={item.value}>{item.text}</TextListItem>
          ))}
        </TextList>
        <Text variant={TextVariant.BodyText} style={{ fontWeight: 700 }}>
          Din estimerte pris:
        </Text>
        <Text variant={TextVariant.Header2}>kr. {offer.vehicleMaintenanceWizard.offerRequest?.totalPrice},-</Text>
        <Button
          type="button"
          width="full"
          onClick={() => {
            handleButtonClick(dataLayer, 'Finn ledig time');
            window.open(offer.vehicleMaintenanceWizard.nextAction.url, '_blank');
          }}
        >
          Finn ledig time
        </Button>
      </S.StyledOfferCard>
      <Text variant={TextVariant.BodyText} style={{ marginTop: 16, marginBottom: 12 }}>
        Dette er prisen vi estimerer for din bil, basert på kjørte kilometer og alder. Prisen kan bli justert når vår
        mekaniker har sett på bilen din og gått igjennom servicehistorikken.
      </Text>
      <Text variant={TextVariant.SubHeader} style={{ marginBottom: 0 }}>
        Ønsker du tilbud på noe annet?
      </Text>
      <Text variant={TextVariant.BodyText}>
        Send oss en forespørsel med beskrivelse av hva du ønsker, så kommer vi tilbake til deg med et tilbud per e-post.
      </Text>
      <S.StyledBottomButton
        text="Be om pris"
        variant="secondary"
        onClickFn={() => {
          handleButtonClick(dataLayer, 'Be om pris');
          updateShouldShowManualRequest(true);
          updateShouldShowAnOffer(false);
        }}
      />
    </>
  );
};

export const PriceOffer = () => {
  const dataLayer = useGTMDataLayer();
  const { resetState } = useServiceCalculator();

  useSendGTMEventOnce(funnelPayload('Servicekalkulator', 3), []);

  return (
    <S.StyledWrapper>
      <BreadCrumb paddingTop={false} />
      <S.DesktopWrapper>
        <S.StyledInnerContent>
          <TitleComponent />
          <PriceOfferContentComponent />
        </S.StyledInnerContent>
        <S.StyledConfirmationImageWrapper>
          <NafCenterOffer />
        </S.StyledConfirmationImageWrapper>
      </S.DesktopWrapper>
      <S.MobileWrapper>
        <S.StyledInnerContent>
          <S.StyledMobileHeader>
            <TitleComponent />
            <S.StyledConfirmationImageWrapper>
              <NafCenterOffer />
            </S.StyledConfirmationImageWrapper>
          </S.StyledMobileHeader>
          <PriceOfferContentComponent />
        </S.StyledInnerContent>
      </S.MobileWrapper>
      <S.StyledBottomButton
        text="Kalkulér på nytt"
        variant="outline"
        onClickFn={() => {
          handleButtonClick(dataLayer, 'Kalkulér på nytt');
          resetState();
        }}
      />
    </S.StyledWrapper>
  );
};
