import React, { useEffect, useState } from 'react';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { CardSize, CardNavigation } from '@naf/cards';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CenterProductArticleType } from '../../../../../types/centerProductArticleType';
import S from './styles';
import { NavCardLink } from '../../../components/styled-link/StyledLink';
import { ProductPrice } from './ProductPrice';
import { useWindowLocation } from '../../../hooks/Window/useWindowLocation';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import BlockContent from '../../../components/block-content/BlockContent';
import useSelector from '../../../redux/typedHooks';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { nafCenterBasePath } from '../../../routers/NafCenterRouter';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { MetaData } from '../../../components/metaData/MetaData';
import { ServiceCalculator } from '../serviceCalculator/ServiceCalculator';
import { actions as CenterProductActions } from '../../../redux/modules/centerProducts';
import { actions as MyMembershipActions } from '../../../redux/modules/myMembership';
import ServiceCalculatorProvider from '../serviceCalculator/context/ServiceCalculatorContext';

export const path = ':productSlug';

export const ProductCard = ({
  otherProducts,
}: {
  otherProducts: CenterProductArticleType['relatedProductArticles'];
}) => (
  <S.OtherTests>
    <GridCol s="12" m="12" l="12" xl="12">
      <Text variant={TextVariant.Header2}>Andre tjenester</Text>
    </GridCol>
    {otherProducts?.map((product) => (
      <S.ShortCutCol s="12" m="6" l="4" xl="4" key={product.title}>
        <NavCardLink
          to={(product.link && product.link.data && `/${nafCenterBasePath}/${product.link.data.slug}`) || ''}
        >
          <CardNavigation title={product.title} size={CardSize.Medium} isInternalLink height="100%">
            {product.ingress}
          </CardNavigation>
        </NavCardLink>
      </S.ShortCutCol>
    ))}
  </S.OtherTests>
);

interface Props {
  centerProductArticle: CenterProductArticleType;
  productSlug: string;
}

export const NafProductArticle = ({ centerProductArticle, productSlug }: Props) => {
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();
  const [centerId] = useSessionStorage('centerId', undefined);
  const { products: centerProducts, isUpdating } = useSelector((state) => state.centerProducts);
  const { title, customDisplay, ingress, body, products, relatedProductArticles, seoConfig, meta, metaDisplay } =
    (centerProductArticle as CenterProductArticleType) || {};
  const seoTitle = seoConfig?.title || title;
  const windowPath = useWindowLocation();
  const location = useLocation();
  const customerInformation = useSelector((state) => state.myMembership.customerInformation.data);

  const [shouldRenderServiceCalculator, setShouldRenderServiceCalculator] = useState(false);

  useEffect(() => {
    if (products && products[0]?.name === 'ServiceCalculator' && location.state?.custom && customerInformation) {
      setShouldRenderServiceCalculator(true);
    }
  }, [customerInformation, location.state?.custom, products]);

  useEffect(() => {
    if (simpleToken && !customerInformation) {
      dispatch(MyMembershipActions.getCustomerInformation.request(simpleToken));
    }
  }, [simpleToken, dispatch, customerInformation]);

  useEffect(() => {
    if (centerId && productSlug) {
      dispatch(
        CenterProductActions.getCenterProducts.request(productSlug, { id: centerId as number, token: simpleToken }),
      );
    }
  }, [productSlug, simpleToken, centerId, dispatch]);

  const hasCampaign =
    products &&
    products.length > 0 &&
    products.some(
      (value) =>
        (value.memberPrice && value.memberPrice.isCampaign) ||
        (value.nonMemberPrice && value.nonMemberPrice.isCampaign),
    );

  if (shouldRenderServiceCalculator) {
    return (
      <ServiceCalculatorProvider>
        <ServiceCalculator
          centerId={centerId}
          centerProductArticle={centerProductArticle}
          productSlug={productSlug}
          isMember={customerInformation.membership?.isValid}
        />
      </ServiceCalculatorProvider>
    );
  }

  return (
    <LayoutWithMainContent
      title={title}
      description={ingress || seoConfig?.introduction || ''}
      imgUrl=""
      url={windowPath}
      gtmArgs={{
        page_type: 'NafCenterProductArticle',
        pageCategory: 'NafCenterProductArticle',
        contentId: productSlug,
      }}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle={seoTitle}
      seoDescription={seoConfig?.introduction || undefined}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <BreadCrumb paddingTop={false} />
      <S.HeaderColorBox />
      <Grid>
        <S.HeaderGridCol s="12" m="12" l="12" xl="12">
          <Text variant={TextVariant.Display}>{title}</Text>
          {hasCampaign && <S.StyledLabel text="Kampanje" variant="moss" />}
        </S.HeaderGridCol>
        {products && products.length > 0 && !isUpdating && <ProductPrice products={centerProducts || products} />}
        {customDisplay && customDisplay.length > 0 && (
          <S.CustomDisplayCol s="12" m="12" l="8" xl="8">
            <BlockContent value={customDisplay} />
          </S.CustomDisplayCol>
        )}
        <S.BodyCol s="12" m="12" l="8" xl="8">
          {ingress && <S.Ingress>{ingress}</S.Ingress>}
          {body && body.length > 0 && (
            <S.Body>
              <BlockContent value={body.filter((block) => block._type !== 'quoteComponent')} />
            </S.Body>
          )}
          <MetaData meta={meta} metaDisplay={metaDisplay} />
        </S.BodyCol>
        {body && body.some((block) => block && block._type === 'quoteComponent') && (
          <S.QuoteCol s="12" m="12" l="12" xl="12">
            <BlockContent value={body.filter((block) => block._type === 'quoteComponent')} />
          </S.QuoteCol>
        )}
        {relatedProductArticles && relatedProductArticles.length > 0 && (
          <ProductCard otherProducts={relatedProductArticles.slice(0, 3)} />
        )}
      </Grid>
    </LayoutWithMainContent>
  );
};
