/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { FormProvider, useFormState } from 'react-hook-form';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { useServiceCalculator } from '../../context/ServiceCalculatorContext';
import * as S from '../../Styles';
import { WorkProposal } from './WorkProposal';
import Loader from '../../../../../components/loader';
import { funnelPayload } from '../../../../../../../types/gtmFunnel';
import { useSendGTMEventOnce } from '../../../../../hooks/GTM/useSendGTMEventOnce';
import { handleButtonClick } from '../../ServiceCalculator.utils';
import { useGTMDataLayer } from '../../../../../hooks/GTM/useGTMDataLayer';

interface SelectEquipmentStepProps extends Partial<StepWizardChildProps> {
  onUpdateProgressBarCompletedPercent: () => void;
  onUpdateProgressBarLabel: () => void;
}

export const SelectWorkProposalStep = ({
  onUpdateProgressBarCompletedPercent,
  onUpdateProgressBarLabel,
  isActive,
  currentStep,
  goToStep,
}: SelectEquipmentStepProps) => {
  const dataLayer = useGTMDataLayer();
  const {
    getOfferWithSelectedWorkProposal,
    offer,
    workProposalForm,
    updateShouldShowAnOffer,
    updateShouldShowManualRequest,
  } = useServiceCalculator();
  const { isValid, errors, isSubmitting } = useFormState({
    control: workProposalForm.control,
  });

  const onSubmit = async () => {
    handleButtonClick(dataLayer, 'Neste');
    if (isValid) {
      const formValues = workProposalForm.getValues();
      if (formValues.workProposal > 0) {
        await getOfferWithSelectedWorkProposal(formValues);
      } else {
        updateShouldShowManualRequest(true);
        updateShouldShowAnOffer(false);
      }
    }
  };

  useEffect(() => {
    if (isActive) {
      onUpdateProgressBarCompletedPercent();
      onUpdateProgressBarLabel();
    }
  }, [isActive, onUpdateProgressBarCompletedPercent, onUpdateProgressBarLabel]);

  useSendGTMEventOnce(funnelPayload('Servicekalkulator', 2), []);

  return offer.vehicleMaintenanceWizard?.workProposalGroups?.length === 0 || (isValid && isSubmitting) ? (
    <S.LoaderWrapper>
      <Loader />
      <Text variant={TextVariant.BodyText}>Vi samler deler og priser for å kalkulere din pris</Text>
    </S.LoaderWrapper>
  ) : (
    <FormProvider {...workProposalForm}>
      <form onSubmit={workProposalForm.handleSubmit(onSubmit)}>
        <S.StyledGrid>
          <S.StyledGridCol s="12" m="6" l="6" xl="6">
            {offer.vehicleMaintenanceWizard?.workProposalGroups?.length > 0 && (
              <WorkProposal
                name="workProposal"
                control={workProposalForm.control}
                errorMessage={errors.workProposal?.message}
                rules={{
                  required: {
                    value: true,
                    message: 'Du må velge et alternativ',
                  },
                  min: { value: 0, message: 'Du må velge et alternativ' },
                }}
                workProposals={offer.vehicleMaintenanceWizard?.workProposalGroups}
              />
            )}
            <S.ButtonDivider className="row-gap" />
            <S.StyledButtonsContainer className="full-width">
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  handleButtonClick(dataLayer, 'Tilbake');
                  workProposalForm.setValue('workProposal', -2);
                  goToStep(currentStep - 1);
                }}
              >
                Tilbake
              </Button>
              <Button type="submit" variant="outline">
                Neste
              </Button>
            </S.StyledButtonsContainer>
          </S.StyledGridCol>
        </S.StyledGrid>
      </form>
    </FormProvider>
  );
};
