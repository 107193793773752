/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FormProvider, useFormState } from 'react-hook-form';
import { StepWizardChildProps } from 'react-step-wizard';
import { useDispatch } from 'react-redux';
import { UserInteractionVariant } from '@naf/user-interaction';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import debounce from 'lodash/debounce';
import { useAuth0Token } from '../../../../../hooks/useAuth0Token';
import { VehicleObject } from '../../../../../../../types/myVehiclesType';
import Loader from '../../../../../components/loader';
import { useServiceCalculator } from '../../context/ServiceCalculatorContext';
import { NafCenter } from './NafCenter';
import { Vehicle } from './Vehicle';
import { Mileage } from './Mileage';
import { useVehicleSearch } from '../../../../../hooks/useVehicleSearch';
import { actions as VehicleActions } from '../../../../../redux/modules/myVehicles';
import { Equipment } from './Equipment';
import * as S from '../../Styles';
import useSelector from '../../../../../redux/typedHooks';
import { funnelPayload } from '../../../../../../../types/gtmFunnel';
import { useSendGTMEventOnce } from '../../../../../hooks/GTM/useSendGTMEventOnce';
import { handleButtonClick } from '../../ServiceCalculator.utils';
import { useGTMDataLayer } from '../../../../../hooks/GTM/useGTMDataLayer';
import { useServiceCalculatorApi } from '../../../../../hooks/useServiceCalculatorApi';

interface SelectVehicleStepProps extends Partial<StepWizardChildProps> {
  nafCenterId: number;
  onUpdateProgressBarCompletedPercent: (value: number) => void;
  onUpdateProgressBarLabel: (value: string) => void;
}

export const SelectVehicleStep = ({
  nafCenterId,
  onUpdateProgressBarCompletedPercent,
  onUpdateProgressBarLabel,
  isActive,
  goToStep,
  currentStep,
}: SelectVehicleStepProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dataLayer = useGTMDataLayer();
  const { simpleToken } = useAuth0Token();
  const { vehicleList } = useSelector((state) => state.myVehicles);
  const [centers, setCenters] = useState<{ label: string; value: number }[]>([]);
  const [vehicles, setVehicles] = useState<{ label: string; value: string }[]>([]);
  const { vehicleInResponse, lookup } = useVehicleSearch();
  const [vehicleLookup, setVehicleLookup] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { getAvailableCenters } = useServiceCalculatorApi();
  const { getOffer, getOfferWithPossibleEquipment, vehicleForm, workProposalForm, offer, resetOffer } =
    useServiceCalculator();
  const { isValid, isSubmitting, errors } = useFormState({
    control: vehicleForm.control,
  });

  const shouldBeSecondStep =
    offer.vehicleMaintenanceWizard?.workProposalGroups?.length > 0 && workProposalForm.getValues().workProposal !== -2;

  const onSubmit = async () => {
    handleButtonClick(dataLayer, 'Neste');

    if (!isValid) {
      return;
    }

    workProposalForm.reset();
    onUpdateProgressBarCompletedPercent(50);

    const formValues = vehicleForm.getValues();
    if (formValues.equipments.length > 0) {
      await getOfferWithPossibleEquipment(formValues);
    } else {
      await getOffer(formValues);
    }
  };

  const validateNafCenter = () => {
    const { centerId } = vehicleForm.getValues();

    if (centers.find((v) => v.value === centerId)) {
      return true;
    }

    return 'Velg et senter.';
  };

  const validateVehicle = () => {
    const { licensePlateNumber, vehicleName, isEv } = vehicleForm.getValues();

    if (!licensePlateNumber || licensePlateNumber === '') {
      return 'Skriv inn bilens registreringsnummer.';
    }

    if (vehicles.find((v) => v.value === licensePlateNumber)) {
      return true;
    }

    if (vehicleName) {
      return isEv ? true : 'For øyeblikket kan vi kun estimere pris for elbiler.';
    }
    return 'Vi fant ingen kjøretøy. Sørg for at registreringsnummer er korrekt, og prøv igjen.';
  };

  const handleLookup = debounce((inputValue: string) => {
    resetOffer();
    setVehicleLookup('');

    if (!inputValue.includes(' ')) {
      lookup({ token: simpleToken, licensePlateNumber: inputValue });
    }
  }, 500);

  const handleChange = (label: string, value: string) => {
    resetOffer();
    if (vehicles.find((v) => v.value === value)) {
      vehicleForm.setValue('vehicleName', label?.split(' ').slice(1).join(' '));
      vehicleForm.setValue('isEv', true);
      vehicleForm.setValue('licensePlateNumber', value);
      vehicleForm.clearErrors(['licensePlateNumber']);
    }
  };

  useEffect(() => {
    if (!vehicleList.data && simpleToken) {
      dispatch(VehicleActions.getMyVehicles.request(simpleToken));
    }
  }, [vehicleList, simpleToken, dispatch]);

  useEffect(() => {
    const fetchCenters = async () => {
      const centerArray = await getAvailableCenters();

      if (centerArray) {
        setCenters(
          centerArray.map((v) => ({
            label: v.title,
            value: v.centerId,
          })),
        );
      }
    };

    fetchCenters();
  }, [getAvailableCenters]);

  useEffect(() => {
    if (vehicleList && vehicleList.data && !vehicleList.isUpdating) {
      const vehicleArray = Object.values(vehicleList.data) as VehicleObject[];
      setVehicles(
        vehicleArray
          ?.filter((v) => v && v.isEv)
          ?.map((v) => ({
            label: `${v.licensePlateNumber} ${v.manufactureName} ${v.modelName}`,
            value: v.licensePlateNumber,
          })),
      );
    }
  }, [vehicleList]);

  useEffect(() => {
    const { licensePlateNumber } = vehicleForm.getValues();

    if (vehicleInResponse) {
      setVehicleLookup(`${vehicleInResponse.manufactureName} ${vehicleInResponse.modelName}`);
      vehicleForm.setValue('vehicleName', `${vehicleInResponse.manufactureName} ${vehicleInResponse.modelName}`);
      vehicleForm.setValue('isEv', vehicleInResponse.isEv);
      vehicleForm.trigger();
    } else if (licensePlateNumber.length > 0) {
      vehicleForm.resetField('vehicleName');
      vehicleForm.resetField('isEv');
      vehicleForm.trigger();
    }
  }, [vehicleInResponse, vehicleForm]);

  useEffect(() => {
    if (isActive) {
      if (offer.vehicleMaintenanceWizard?.possibleEquipment.length > 0) {
        onUpdateProgressBarCompletedPercent(50);
      } else {
        onUpdateProgressBarCompletedPercent(25);
        onUpdateProgressBarLabel('Velg senter og bil');
      }
    }
  }, [isActive, onUpdateProgressBarCompletedPercent, onUpdateProgressBarLabel, offer]);

  useEffect(() => {
    if (offer.vehicleMaintenanceWizard && !vehicleInResponse) {
      const { licensePlateNumber } = vehicleForm.getValues();
      lookup({ token: simpleToken, licensePlateNumber });
    }
  }, [offer, vehicleInResponse, vehicleForm, lookup, simpleToken]);

  useEffect(() => {
    if (shouldBeSecondStep) {
      goToStep(currentStep + 1);
    }
  }, [shouldBeSecondStep, goToStep, currentStep]);

  useEffect(() => {
    setIsLoading(centers.length === 0 || vehicleList.isUpdating || (isValid && isSubmitting));
  }, [vehicleList, isValid, isSubmitting, centers]);

  useSendGTMEventOnce(funnelPayload('Servicekalkulator', 1), []);

  return (
    <FormProvider {...vehicleForm}>
      {isLoading || shouldBeSecondStep ? (
        <S.LoaderWrapper>
          <Loader />
          {isSubmitting && (
            <Text variant={TextVariant.BodyText}>Vi samler deler og priser for å kalkulere din pris</Text>
          )}
        </S.LoaderWrapper>
      ) : (
        <form onSubmit={vehicleForm.handleSubmit(onSubmit)}>
          <S.StyledGrid>
            <S.StyledGridCol s="12" m="6" l="6" xl="6">
              <S.StyledInnerColContainer>
                <NafCenter
                  name="centerId"
                  control={vehicleForm.control}
                  defaultValue={nafCenterId}
                  centers={centers}
                  errorMessage={errors.centerId?.message}
                  rules={{
                    validate: validateNafCenter,
                  }}
                />
                <Vehicle
                  name="licensePlateNumber"
                  control={vehicleForm.control}
                  errorMessage={errors.licensePlateNumber?.message}
                  defaultValue={vehicles.length > 0 ? vehicles[0].value : ''}
                  rules={{
                    validate: validateVehicle,
                  }}
                  vehicles={vehicles}
                  handleChange={handleChange}
                  vehicleLookup={vehicleLookup}
                  handleLookup={handleLookup}
                />
                <Mileage
                  name="mileage"
                  control={vehicleForm.control}
                  errorMessage={errors.mileage?.message}
                  rules={{
                    required: {
                      value: true,
                      message: 'Vi trenger kilometerstand for å kunne estimere pris.',
                    },
                    min: {
                      value: 1,
                      message: 'Kilometerstand kan ikke være 0.',
                    },
                    max: {
                      value: 1000000,
                      message: 'Kilometerstand kan ikke overskride 1 000 000.',
                    },
                  }}
                />
                {offer.vehicleMaintenanceWizard?.possibleEquipment.length > 0 && (
                  <>
                    <S.StyledUserInteraction
                      className="full-width"
                      title="Velg tilgjengelig ekstrautstyr for ditt kjøretøy dersom det er relevant."
                      variant={UserInteractionVariant.Info}
                    />
                    <Equipment
                      name="equipments"
                      control={vehicleForm.control}
                      equipments={offer.vehicleMaintenanceWizard?.possibleEquipment}
                      rules={{
                        required: false,
                      }}
                    />
                  </>
                )}
                <S.ButtonDivider />
                <S.StyledButtonsContainer className="full-width">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => {
                      handleButtonClick(dataLayer, 'Tilbake');
                      history.goBack();
                    }}
                  >
                    Tilbake
                  </Button>
                  <Button type="submit" variant="outline">
                    Neste
                  </Button>
                </S.StyledButtonsContainer>
              </S.StyledInnerColContainer>
            </S.StyledGridCol>
          </S.StyledGrid>
        </form>
      )}
    </FormProvider>
  );
};
